/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // MENU
          $(document).ready(function(){
              $("#nav-mobile").html($("#nav-main").html());
              $("#nav-trigger span").click(function(){
                  if ($("nav#nav-mobile ul").hasClass("expanded")) {
                      $("nav#nav-mobile ul.expanded").removeClass("expanded").slideUp(250);
                      $(this).removeClass("open");
                  } else {
                      $("nav#nav-mobile ul").addClass("expanded").slideDown(250);
                      $(this).addClass("open");
                  }
              });
          });
        // ANIMATE DROPDOWN

        // POPOVER
          $(function () {
              $('[data-toggle="popover"]').popover({
                  trigger:'hover',
                  offset: '12 0'
              });
          });

        // SCROLL TO TOP
          $(window).scroll(function () {
              if ($(this).scrollTop() > 50) {
                  $('#back-to-top').fadeIn();
              } else {
                  $('#back-to-top').fadeOut();
              }
          });
          $('#back-to-top').click(function () {
              $('body,html').animate({
                  scrollTop: 0
              }, 800);
              return false;
          });
          $('#back-to-top').tooltip('show');
          // SCROLL TO TOP MOBILE
          $('#back-to-top-mobile').click(function () {
                  $('body,html').animate({
                      scrollTop: 0
                  }, 800);
                  return false;
          });
        // VIDEO
          // poster frame click event
          $(document).on('click','.js-videoPoster',function(ev) {
              ev.preventDefault();
              var $poster = $(this);
              var $wrapper = $poster.closest('.js-videoWrapper');
              videoPlay($wrapper);
          });

            // play the targeted video (and hide the poster frame)
          function videoPlay($wrapper) {
              var $iframe = $wrapper.find('.js-videoIframe');
              var src = $iframe.data('src');
              // hide poster
              $wrapper.addClass('videoWrapperActive');
              // add iframe src in, starting the video
              $iframe.attr('src',src);
          }
            /*
            // stop the targeted/all videos (and re-instate the poster frames)
          function videoStop($wrapper) {
              // if we're stopping all videos on page
              if (!$wrapper) {
                  var $wrapper = $('.js-videoWrapper');
                  var $iframe = $('.js-videoIframe');
                  // if we're stopping a particular video
              } else {
                  var $iframe = $wrapper.find('.js-videoIframe');
              }
              // reveal poster
              $wrapper.removeClass('videoWrapperActive');
              // remove youtube link, stopping the video from playing in the background
              $iframe.attr('src','');
          }
            */

        // END

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.